import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { type StaticContext } from 'react-router';
import { Redirect, Route, type RouteComponentProps, Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import PrivateRoute from './PrivateRoute';
import rootComponents from './RootComponents';
import { rootRoutes } from './RootPaths';
import appHistory from './history';
import { useHomePath } from './hooks/useHomePath';
import { useRedirectUser } from './hooks/useRedirectUser';
import { useSendLicenseLimitNotification } from './hooks/useSendLicenseLimitNotification';

import IdleTimer from '../../components/IdleTimer';
import Loader from '../../components/Loader';
import PageNotFound from '../../components/PageNotFound';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useVerifyBrowserVersion } from '../../hooks/useVerifyBrowserVersion';
import { applicationIsLoading } from '../../redux/application/selectors';
import { userId as userIdSelector } from '../../redux/selectors';
import useScreebTracking from '../../services/tracking/screeb';

const RootModule = () => {
  const isLoading = useSelector(applicationIsLoading);
  const userId = useSelector(userIdSelector) || undefined;
  const email = useUserEmail();
  const HomePath = useHomePath();

  useVerifyBrowserVersion();
  useScreebTracking();
  useRedirectUser();
  useSendLicenseLimitNotification();

  const isFetching = email && !userId;

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <Router history={appHistory}>
      <Suspense fallback={<Loader />}>
        {window.Worker && <IdleTimer />}
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route
              component={rootComponents.ROOT_SIGNIN_ROUTE.component}
              exact
              path={rootRoutes.ROOT_SIGNIN_ROUTE.path}
            />
            <Route
              component={rootComponents.ROOT_SIGNUP_ROUTE.component}
              exact
              path={rootRoutes.ROOT_SIGNUP_ROUTE.path}
            />
            <Route
              component={rootComponents.ROOT_FORGOT_PASSWORD_ROUTE.component}
              exact
              path={rootRoutes.ROOT_FORGOT_PASSWORD_ROUTE.path}
            />
            <Route
              component={rootComponents.ROOT_LOGIN_SUCCESS.component}
              path={rootRoutes.ROOT_LOGIN_SUCCESS.path}
            />
            {(Object.keys(rootRoutes) as Array<keyof typeof rootRoutes>).map(route => {
              return (
                <PrivateRoute
                  component={rootComponents[route].component}
                  exact={rootRoutes[route].exact}
                  key={route}
                  path={rootRoutes[route].path}
                />
              );
            })}
            <Redirect to={HomePath} />
            <Route
              render={(
                pageNotFoundProps: RouteComponentProps<
                  {
                    [x: string]: string | undefined;
                  },
                  StaticContext,
                  unknown
                >,
              ) => <PageNotFound {...pageNotFoundProps} />}
            />
          </Switch>
        </QueryParamProvider>
      </Suspense>
    </Router>
  );
};

export default RootModule;
