import { OpenFeature } from '@openfeature/react-sdk';

import { isOnPremise } from '@/config';

import {
  type ValueFlagName,
  type BoolFlagName,
  DEFAULT_FLAGS,
  type FlagValue,
} from './defaultFlags';

const DEFAULT_BOOL = false;
const DEFAULT_VARIANT = '';

function getInitialFlags(): Record<string, FlagValue> {
  return Object.fromEntries(
    Object.entries(DEFAULT_FLAGS).map(([flag, flagValue]) => {
      const flagEnvironmentKey = `FLAG_${flag.toUpperCase()}`;
      // eslint-disable-next-line no-underscore-dangle
      const isFlagInEnvironmentVariables = flagEnvironmentKey in window._env_;
      if (isFlagInEnvironmentVariables) {
        return [
          flag,
          {
            // eslint-disable-next-line no-underscore-dangle
            enabled: (window._env_?.[flagEnvironmentKey] ?? '').toLowerCase() === 'true',
          },
        ];
      }
      return [flag, flagValue];
    }),
  );
}

export const hasFeatureActive = (featureKey: BoolFlagName): boolean => {
  if (isOnPremise()) {
    const onPremiseFlags = getInitialFlags();

    return onPremiseFlags[featureKey]?.enabled ?? false;
  }

  const client = OpenFeature.getClient();

  const value = client.getBooleanValue(featureKey, DEFAULT_BOOL);

  return value;
};

export const getFeatureValue = (featureKey: ValueFlagName): string => {
  if (isOnPremise()) {
    const onPremiseFlags = getInitialFlags();

    return onPremiseFlags[featureKey]?.value ?? '';
  }

  const client = OpenFeature.getClient();

  const details = client.getStringDetails(featureKey, DEFAULT_VARIANT);

  return details.value;
};

export const isFlagActivated = (flagName: string): boolean => {
  return hasFeatureActive(flagName as BoolFlagName);
};

export default function useFlag(flagName: BoolFlagName): boolean {
  return isFlagActivated(flagName);
}
