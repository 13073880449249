import _get from 'lodash/get';

export const baseURL = _get(window, '_env_.PROJECT_NAME__LABEL_FRONTEND');

export const getAnalyticsUrl = (projectId: string): string => `${getMenuUrl(projectId)}/analytics`;

export const getAuditTrailUrl = (projectId: string): string => `${getMenuUrl(projectId)}/audit`;

export const getCopilotUrl = (projectId: string): string => `${getMenuUrl(projectId)}/copilot`;

export const getQueueUrl = (projectId: string): string => `${getMenuUrl(projectId)}/queue`;

export const getSettingsAdminUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/admin`;

export const getSettingsDataUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/data`;

export const getSettingsInterfaceUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/interface`;

export const getSettingsMembersUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/members`;

export const getSettingsQualityUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/quality`;

export const getSettingsModelsUrl = (projectId: string): string =>
  `${getSettingsUrl(projectId)}/models`;

const getSettingsUrl = (projectId: string): string => `${getMenuUrl(projectId)}/settings`;

const getMenuUrl = (projectId: string): string => `/${baseURL}/projects/${projectId}/menu`;
